import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import adminRouter from './admin'

import { Role } from '@/_helpers/role'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: Home,
    alias: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    // route level code-splitting
    // this generates a separate chunk (registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Registration.vue')
  },
  {
    path: '/invitation-account-system_admin',
    name: 'InvitationCompanyAdmin',
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyAdminInvite.vue')
  },
  {
    path: '/invitation-account-account_user',
    name: 'InvitationCompanyUser',
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyUserInvite.vue')
  },
  {
    path: '/recovery/:uuid?/:token?',
    name: 'Recovery',
    // route level code-splitting
    // this generates a separate chunk (recovery.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Recovery.vue')
  },
  {
    path: '/auth/:confirmation/:uuid?/:token',
    name: 'Confirmation',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Confirmation.vue')
  },
  {
    path: '/history',
    name: 'History',
    // route level code-splitting
    // this generates a separate chunk (history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    // route level code-splitting
    // this generates a separate chunk (history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    props: true,
    component: () => import(/* webpackChunkName: "history" */ '../views/Chat.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (contacts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
    alias: '/members'
  },
  {
    path: '/new-contact/:number?/:firstName?/:lastName?',
    name: 'NewContact',
    // route level code-splitting
    // this generates a separate chunk (new-contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "new-contact" */ '../views/NewContact.vue')
  },
  {
    path: '/new-member',
    name: 'NewMember',
    // route level code-splitting
    // this generates a separate chunk (new-contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "new-contact" */ '../views/NewContact.vue')
  },
  {
    path: '/import-contacts',
    name: 'ImportContacts',
    // route level code-splitting
    // this generates a separate chunk (contacts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportContacts.vue')
  },
  {
    path: '/analytics',
    name: 'Analytics',
    // route level code-splitting
    // this generates a separate chunk (analytics.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue')
  },
  {
    path: '/imported-contacts',
    name: 'ImportedContacts',
    // route level code-splitting
    // this generates a separate chunk (contacts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportedContacts.vue')
  },
  {
    path: '/voicemails',
    name: 'Voicemails',
    // this generates a separate chunk (voicemail.[hash].js) for this route
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Voicemails.vue')
  },
  {
    path: '/voicemails/:name',
    name: 'VoicemailsMailboxes',
    // this generates a separate chunk (voicemail.[hash].js) for this route
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Voicemails.vue')
  },
  {
    path: '/queues',
    name: 'Queues',
    // route level code-splitting
    // this generates a separate chunk (queues.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Queues.vue')
  },
  {
    path: '/mailbox/:boxName/:uuid',
    name: 'Mailbox',
    // route level code-splitting
    // this generates a separate chunk (queues.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Mailbox.vue'),
    alias: ['/mailbox/callback']
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    // route level code-splitting
    // this generates a separate chunk (my-profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "my-profile" */ '../views/MyProfile.vue')
  },
  {
    path: '/switch-account',
    name: 'SwitchAccount',
    // route level code-splitting
    // this generates a separate chunk (my-profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.SystemAdmin]
    },
    component: () => import(/* webpackChunkName: "my-profile" */ '../views/SwitchAccountPage.vue')
  },
  {
    path: '/edit-profile/:type?/:id?',
    name: 'EditProfile',
    // route level code-splitting
    // this generates a separate chunk (edit-profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true

    },
    component: () => import(/* webpackChunkName: "edit-profile" */ '../views/EditProfile.vue'),
    alias: ['/edit-contact']
  },
  {
    path: '/user-profile/:type/:id',
    name: 'UserProfile',
    // route level code-splitting
    // this generates a separate chunk (edit-profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "edit-profile" */ '../views/UserProfile.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (settings.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/invoice',
    name: 'Invoice',
    // route level code-splitting
    // this generates a separate chunk (payments.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "payments" */ '../views/Invoices.vue')
  },
  {
    path: '/payments',
    name: 'Payments',
    // route level code-splitting
    // this generates a separate chunk (payments.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue')
  },
  {
    path: '/add-payment-method',
    name: 'AddPaymentMethod',
    // route level code-splitting
    // this generates a separate chunk (queues.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AddPaymentMethod.vue')
  },
  {
    path: '/edit-payment/:id?',
    name: 'EditPaymentMethod',
    // route level code-splitting
    // this generates a separate chunk (queues.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AddPaymentMethod.vue')
  },
  {
    path: '/active-calls',
    name: 'ActiveCalls',
    // route level code-splitting
    // this generates a separate chunk (test2.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "test2" */ '../views/ActiveCalls.vue')
  },
  {
    path: '/search-results/:search?',
    name: 'SearchResults',
    // route level code-splitting
    // this generates a separate chunk (search-results.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "search-results" */ '../views/SearchResults.vue')
  },
  {
    path: '/mailboxes',
    name: 'Mailboxes',
    // route level code-splitting
    // this generates a separate chunk (mailboxes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.User, Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "mailboxes" */ '../views/Mailboxes.vue')
  },
  {
    path: '/create-mailbox',
    name: 'CreateMailbox',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateMailbox.vue'),
    props: { editMode: true }
  },
  {
    path: '/edit-mailbox/:uuid',
    name: 'EditMailbox',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateMailbox.vue')
  },
  {
    path: '/invite-members',
    name: 'InviteMembers',
    // route level code-splitting
    // this generates a separate chunk (invite-members.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "invite-members" */ '../views/InviteMembers.vue')
  },
  {
    path: '/callflow',
    name: 'Callflow',
    // route level code-splitting
    // this generates a separate chunk (callflow.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "callflow" */ '../views/Callflow.vue')
  },
  {
    path: '/create-callflow/:uuid?/:number?',
    name: 'CreateCallflow',
    // route level code-splitting
    // this generates a separate chunk (mailboxes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "mailboxes" */ '../views/CreateCallflow.vue')
  },
  {
    path: '/edit-callflow/:uuid/:number?',
    name: 'EditCallflow',
    // route level code-splitting
    // this generates a separate chunk (mailboxes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "mailboxes" */ '../views/CreateCallflow.vue')
  },
  {
    path: '/activity-log',
    name: 'CallLog',
    // route level code-splitting
    // this generates a separate chunk (mailboxes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "mailboxes" */ '../views/CallLog.vue')
  },
  {
    path: '/invoice-info/:uuid',
    name: 'InvoiceInfo',
    // route level code-splitting
    // this generates a separate chunk (test2.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "test2" */ '../views/Invoice.vue')
  },
  {
    path: '/numbers',
    name: 'Numbers',
    // route level code-splitting
    // this generates a separate chunk (numbers.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "numbers" */ '../views/Numbers.vue')
  },
  {
    path: '/phone-numbers-old',
    name: 'PhoneNumbersOld',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "numbers" */ '../views/PhoneNumbersOld.vue')
  },
  {
    path: '/payments-history',
    name: 'PaymentsHistory',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "numbers" */ '../views/PaymentsHistory.vue')
  },
  {
    path: '/assign-number/:type/:id',
    name: 'AssignNumber',
    // route level code-splitting
    // this generates a separate chunk (assign-number.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      altHeader: true,
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import(/* webpackChunkName: "assign-number" */ '../views/AssignNumber.vue')
  },
  {
    path: '/buy-a-number',
    name: 'BuyANumber',
    // route level code-splitting
    // this generates a separate chunk (buy-a-number.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin],
      altHeader: true
    },
    component: () => import(/* webpackChunkName: "buy-a-number" */ '../views/BuyANumber.vue')
  },
  {
    path: '/buy-new-number',
    name: 'BuyNewNumber',
    meta: {
      layout: 'main'
    },
    component: () => import(/* webpackChunkName: "buy-new-number" */ '../views/BuyNewNumber.vue')
  },
  {
    path: '/buy-new-number-old',
    name: 'BuyNewNumberOld',
    meta: {
      layout: 'main'
    },
    component: () => import(/* webpackChunkName: "buy-new-number" */ '../views/BuyNewNumberOld.vue')
  },
  {
    path: '/phone-numbers',
    name: 'PhoneNumbers',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.User]

    },
    component: () => import('../views/PhoneNumbers.vue')
  },
  {
    path: '/payment-history',
    name: 'PaymentHistory',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import('../views/PaymentHistory.vue')
  },
  {
    path: '/recordings',
    name: 'Recordings',
    meta: {
      layout: 'main',
      authorize: [Role.CompanyAdmin, Role.SystemAdmin, Role.SuperAdmin]
    },
    component: () => import('../views/Recordings.vue')
  },
  ...adminRouter
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta
  const currentUser = store.getters.currentUser

  store.dispatch('setCurrentUser', currentUser)

  // close PopUpContactsDetails component after route changing
  if (from.name === 'Contacts') {
    store.dispatch('components/ContactDetails', false)
  }

  if (authorize && authorize.length) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({
        path: '/login'
        // query: { returnUrl: to.path }
      })
    }

    // check if route is restricted by role
    if (!authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }
  }

  next()
})

export default router
